import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppendScript } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";

import "gatsby-theme-starberry-lomondgroup/src/components/ClientReview/ClientReview.scss";

import {Grid} from "@dadoagency/gatsby-plugin-trustpilot-widget";

const AllAgentReview = ({ Module }) => {

    // useEffect(()=>{
    //     //console.log('comes_on')

    //     if(typeof window !== "undefined"){
    //         AppendScript("https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js", "", () => {});
    //     }
      
    // }, [])

    return (
        <React.Fragment>
            <section className="client-reviews all-agent-review">
                <Container>
                    <Row>
                        <Col>
                            <div className="review-wrapper">
                                {Module.title && <h3>{Module.title}</h3> }
                                {Module.description && <ContentModule Content={Module.description} /> }

                                <div className="content-wrap">
                                    {/* <div id="wd_id"></div> */}
                                    {/* <div className="trustpilot-widget" data-locale="en-GB" data-template-id="539adbd6dec7e10e686debee" data-businessunit-id="51bc122300006400053c1471" data-style-height="500px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en" data-font-family="Montserrat">
                                        <a href="https://uk.trustpilot.com/review/thornleygroves.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                                    </div> */}

                                    <Grid 
                                        language="en"
                                        culture="US"
                                        theme="light"
                                        width="100%"
                                        height="500px"
                                        stars="4,5"
                                    />
                                </div>
                            </div>
                          
                        </Col>
                    </Row>


                </Container>
            </section>
        </React.Fragment>
    );
};
export default AllAgentReview;
